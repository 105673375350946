<template>
    <!-- 
        Модальне вікно створення проекту 
    -->

    <dialogBox >
        <!-- Заголовок -->
        <template v-slot:title>{{ $t('Add') }} {{ form.projectName }}</template>
        <template v-slot:body>
            <!-- Назва проекту -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('projectTitle') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.projectName">
            </div>
            <!-- Опис проекту -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('projectDescription') }}</h4>
                <input type="text" class="form-control" id="contactNumber" v-model="form.description">
            </div>

            <!-- Логотип до проекту -->
            <div class="mb-4">
                <h5 class="fs-15 mb-1">{{ $t('logo') }}</h5>
                <p class="text-muted">{{ $t('ChooseProjectLogo') }}</p>

                <div class="text-center" >
                    <div class="position-relative d-inline-block" >
                        <!-- Вибір лого -->
                        <div class="position-absolute top-100 start-100 translate-middle" >
                            <label for="product-image-input" class="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" aria-label="Select Image" data-bs-original-title="Select Image">
                                <div class="avatar-xs" >
                                    <div class="avatar-title bg-light border rounded-circle text-muted cursor-pointer" >
                                        <i class="ri-image-fill"></i>
                                    </div>
                                </div>
                            </label>
                            <input class="form-control d-none" value="" id="product-image-input" type="file" accept="image/png, image/gif, image/jpeg" @change="handleImageUpload">
                        </div>
                        
                        <!-- Відображення зображення, якщо його обрали -->
                        <img v-if="form.icon != 0" :src="form.icon" alt="" class="rounded avatar-xl">

                        <!-- Відображення ініціалів, якщо не обрали зображення -->
                        <div 
                            v-else
                            :class="`rounded avatar-xl bg-success text-white`"
                            style="padding: 30% 0%;font-size: 2.5em;font-weight: 600;"
                        >
                            {{ this.getInitials(form.projectName != '' ? form.projectName : 'Без назви') }} 
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Слот футера модального вікна з кнопками -->
        <template v-slot:footer-bottom>

            <!-- Закрити вікно -->
            <button 
                class="btn link-danger fw-medium" 
                @click="$emit('close')"
            >
                <i class="ri-close-line me-1 align-middle"></i> 
                {{ $t('close') }}
            </button>

            <!-- Кнопка для додавання нового проекту -->
            <button 
                v-if="this.perm === false"
                type="button" 
                class="btn btn-success" 
                v-on:click="add"
            >
                {{ $t('Add') }}
            </button>

            <!-- Кнопка для редагування проекту -->
            <button 
                v-if="this.perm === true"
                type="button" 
                class="btn btn-success" 
                v-on:click="edit"
            >
                {{ $t('Edit') }}
            </button>
            
        </template>
    </dialogBox>
</template>

<script>
import dialogBox from '@/components/modal/dialog' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { knowledgeBaseProject } from '@/API.js';  // клас для взаємодії з API
import { storeS } from '@/store.js' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new knowledgeBaseProject();

export default {
    components: { dialogBox, Multiselect },
    props:['obj', 'perm'],
    data() {
        return {
            form: { // Дані для форми
                projectName: '', // назва проекоу
                description: "", // опис проекту
                icon: null // іконка
            }
        };
    },
    created(){
        // Ініціалізація даних форми при створенні компонента

        // console.log('this', this);
        // Перевіряємо стан режим редагування
        if(this.perm == true) {
            this.form.projectId = this.obj.projectId
            this.form.projectName = this.obj.projectName
            this.form.description = this.obj.description ? this.obj.description : '-'
            this.form.icon = this.obj.icon
        }
    },
    methods: {
        edit() {
            /**
                * Редагування проекту
            */
            console.log("this.form.projectId", this.form.projectId);
            let id = this.form.projectId;
            delete this.form.projectId;
            delete this.form.createWorkerId;
            delete this.form.createDatetime;
            console.log("id", id);

            apiServe.editProject(id, this.form).then(result=> {
                if(result.status === 'done') {
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "projectId": id,
                        "params": this.form
                    }

                    var errorText = 'Помилка редагування проекту'; 
                    var statusRequest = result.status;
                    var api = 'editProject';
                    var fileRequest = 'src/views/knowledge-base/projects/new.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        add() {
            /**
                * Додавання нового проекту з урахуванням валідації полів.
            */

            if (!this.form.projectName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('EnterNameTicket'));
                return
            }
            if (!this.form.description) {
                //перевіряємо чи вказаний опис
                this.$toast.error(this.$t('EnterDesc'));
                return
            }

            if(this.form.projectName && this.form.description){
                apiServe.addProject(this.form).then(result => {
                    if(result.status==='done'){
                        this.$toast.success(this.$t('сreatedT'));
                        this.$emit('close')
                    } else {
                        this.$toast.error(this.$t('error') + " " + result.error[0]);

                        var errorText = 'Помилка створення проекту'; 
                        var statusRequest = result.status;
                        var api = 'addProject';
                        var fileRequest = 'src/views/knowledge-base/projects/new.vue';
                        var params = this.form;
                        var response = result

                        // Викликаємо функцію для відправки повідомлення в телеграм
                        sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                        .then(response => console.log('Telegram API response:', response.data))
                        .catch(error => console.error('Telegram API error:', error));
                    }
                })
            }
        },
        getInitials(string) {
            // Отримання ініціалів
            var names = string.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();
            
            if (names.length > 1) {
                initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            return initials;
        },
        handleImageUpload(event) {
            // Переводимо зображення в base64
            const file = event.target.files[0];

            // Перевірка, чи файл існує
            if (file) {
                // Створення об'єкта FileReader
                const reader = new FileReader();

                // Визначення функції, яка викликається після завантаження зображення
                reader.onloadend = () => {
                // Отримання base64 даних зображення
                    this.form.icon = reader.result;
                };

                // Зчитування файлу у форматі base64
                reader.readAsDataURL(file);
            }
        },
    },
    mounted() {
        
    },
    computed: {
        user() {
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        },
        categories() {
            /*
                Повертає категорії з глобального стору.
            */
            return storeS.KBCategories
        }
    }
}
</script>